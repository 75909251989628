<template>
    <div class="">
        <layout>
            <template v-slot:header>
                <div class="">
                    <div class="airfreightservice-mainblock w-100 index p-relative">
                        <v-container >
                            <breadcrumbs :items="breadcrumbs" :large="true" class="my-5" :textcolor="`textwhite`"></breadcrumbs>
                            <h1 class="heading-1 white--text">Airfreight shipping</h1>
                            <p class="white--text my-10 d-none d-sm-block">Cost-effective international freight shipping <br/> operating in the United States, Canada, and Mexico</p>
                            <div class="text-20-60 white--text">Get instant quotes</div>
                            <v-btn v-if="!showexpansion && $vuetify.breakpoint.xs" x-large class="secondary px-10 py-4 mt-5 text-transform-none" @click="showexpansion = !showexpansion" depressed>Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                            <v-row v-if="showexpansion || !$vuetify.breakpoint.xs" class="mt-5">
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Pickup city or postal code</label>
                                    <v-autocomplete
                                        v-model="form.from.geoModel"
                                        :items="form.from.geoItems"
                                        :search-input.sync="form.from.geoSearch"
                                        background-color="#EDF2F9"
                                        outlined
                                        class="mt-2"
                                        hide-no-data
                                        hide-selected
                                        item-text="label"
                                        placeholder="Pickup city"
                                        hide-details
                                        return-object
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Requested loading date</label>
                                    <v-menu
                                        v-model="form.from.datepicker"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="computedDateFormatted"
                                                class="mt-2"
                                                outlined
                                                background-color="#EDF2F9"
                                                placeholder="08/05/2020"
                                                append-icon="mdi-calendar-month"
                                                hide-details
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.from.date"
                                            no-title
                                            @input="form.from.datepicker = false"
                                        ></v-date-picker>
                                    </v-menu>

                                </v-col>
                            </v-row>
                            <v-btn  v-if="showexpansion || !$vuetify.breakpoint.xs" x-large class="secondary px-15 py-8 mt-5 text-transform-none" depressed @click="assign_and_redirect">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-container>
                    </div>
                    <div class="agentpage-footerblock w-100 pt-16 pb-8 d-none d-md-block">
                        <v-container>
                            <v-list class="transparent mx-auto" flat >
                                <v-list-item-group class="d-flex flex-row align-self-center justify-space-between h-100">
                                    <v-list-item class="pl-0" :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="Expedited shipping Icon" class="mr-5">
                                                <span class="border white--text">Cost-effectiveness</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="LTL shipping Icon" class="mr-5">
                                                <span class="border white--text">Fast delivery</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="International shipping Icon" class="mr-5">
                                                <span class="border white--text">Guaranteed delivery</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-container>
                    </div>
                    <v-img class="airfreightservice-mainimage d-none d-md-block" src="@/assets/icons/services/airfreight.png" alt=""></v-img>
                </div>
            </template>
            <template v-slot:main>
                <v-container class="py-16">
                    <how-work :item="how_work"/>
                </v-container>
                <v-container class="py-16">
                    <h1 class="heading-2 mb-10">Airfreight shipping benefits</h1>
                    <v-row>
                        <v-col cols="24" sm="6" lg="3" v-for="item in star_card">
                            <star-card :color="item.bg" :white="item.white">
                                <template v-slot:title>
                                    {{item.title}}
                                </template>
                                <template v-slot:description>
                                    {{item.text}}
                                </template>
                            </star-card>
                        </v-col>
                    </v-row>
                </v-container>
                <base-counter :bgimg="require('@/assets/logo/bg-logo-blue-full.svg')" :items="counter_items">
                    <h1 class="heading-2 white--text mb-10">We have a solid <br/> experience of airfreight shipping</h1>
                </base-counter>
                <div class="py-16">
                    <faq-block :data="faq_data"/>
                </div>
                <div class="py-16">
                    <v-container>
                        <h1 class="heading-2">Other Services</h1>
                        <v-row>
                            <v-col cols="12" sm="4" v-for="item in other_services">
                                <service-card :data="item" />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <div class="contact primary py-16">
                    <v-container class="my-6">
                        <contact-us />
                    </v-container>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import HowWork from '@/components/pages/HowWork.vue'
import StarCard from '@/components/base/StarCard.vue'
import BaseCounter from '@/components/base/BaseCounter.vue'
import FaqBlock from '@/components/pages/FAQ.vue'
import ServiceCard from '@/components/base/ServiceCard'
import ContactUs from '@/components/pages/ContactUs.vue'

import geodataMixin from '@/mixins/geodataMixin'

export default {
    mixins: [geodataMixin],
    components: {
        Layout,
        Breadcrumbs,
        HowWork,
        StarCard,
        BaseCounter,
        FaqBlock,
        ServiceCard,
        ContactUs
    },
    data: () => ({
        showexpansion: false,
        calculator: {
            zip: '',
            date: new Date().toISOString().substr(0, 10),
            dateFormatted: new Date().toISOString().substr(0, 10),
            datepicker: false,
        },
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Airfreight',
                disabled: true,
            }
        ],
        how_work: {
            title: 'AirfreightShipping: <br />How Does it Work',
            subtitle: 'Airfreight shipping services are fast and efficient that makes it so valuable to companies needing to get products to international customers quickly or stay on track with a right on schedule manufacturing model.',
            list: [
                {
                    icon: require('@/assets/icons/svg/hwork/msg.svg'),
                    text: 'Submit your request and enter the shipment details',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/dir.svg'),
                    text: 'We examine your request and propose several effective solutions',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/truck.svg'),
                    text: 'You choose the most suitable solution and we provide the carrier',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/box.svg'),
                    text: 'Your freight is delivered',

                },
            ]
        },
        star_card: [
            {
                title: 'Fast delivery',
                text: 'The fastest shipping method with the most reliable arrival and departure times',
                bg: 'bg-transparent'
            },
            {
                title: 'Guaranteed delivery',
                text: 'We guarantee a high level of security and a reduced risk of theft and damage',
                bg: 'bg-transparent'
            },
            {
                title: 'Flexibility',
                text: 'We are working directly with the air companies that have a large network of destinations covering almost the entire world. You can send the shipment almost to any destination',
                bg: 'bg-transparent'
            },
            {
                title: 'Cost-effectiveness',
                text: 'As the transportation time for air freight is as short as possible, the insurance premium is lower. Even if air freight can be expensive, this saves you the majority of insurance costs',
                bg: 'bg-blue',
                white: 'white--text'
            },

        ],
        counter_items: [
            {
                quantity: 50,
                unit: 'k',
                text: 'Over 50 k of loyal <br/> clients trust us',
            },
            {
                quantity: 3,
                unit: 'b',
                text: 'We shipped over 2 675 186 586 pounds <br/>of various freights internationally airfreight',
            },
            {
                quantity: 3,
                unit: 'm',
                text: 'We delivered over <br/>3 millions of airfreight',
            },
        ],
        faq_data: {
            title: 'Frequently Asked Questions about Airfreight shipping',
            text: 'Please, read the FAQs for the airfreight shipping. If you have any additional questions, don’t hesitate to contact us info@freightcrew.us',
            items: [
                {
                    title: 'What if I have a time-sensitive shipment?',
                    text: 'When your freight needs to be delivered as soon as possible, air freight is the best solution compared to sea freight or road transport.'
                },
                {
                    title: 'How much does airfreight shipping cost?',
                    text: 'Airfreight is a cost-effective shipping mode, because the transportation time is very short, so the insurance premium is lower. Even if air freight can seem expensive, it saves you the majority of insurance costs.'
                },
                {
                    title: 'Can you ship to any country?',
                    text: 'At the moment Freight Crew provides time-limited deliveries in the United States, Canada, and Mexico, but we are working on expanding our destinations.'
                },
                {
                    title: 'How do track my international shipping?',
                    text: 'The tracking number provided by Freight Crew can help in determining the delivery time and status of your shipment. Thus you can always check where your shipment is and get ahead of any problems that may arise.'
                },
            ]
        },
        other_services: [
            {
                img: 'svg/shipping/expedited-blue.svg',
                title: 'Expedited Shipping',
                text: 'This transportation service gives your cargo a special shipment priority over others, making the delivery process faster',
                to: 'frontend.services.expedited'
            },
            {
                img: 'svg/shipping/ltl-blue.svg',
                title: 'LTL Shipping',
                text: 'Less-than-truckload shipping or less than load (LTL) is the shipping of relatively small freight',
                to: 'frontend.services.ltl'
            },
            {
                img: 'svg/shipping/international-blue.svg',
                title: 'International shipping',
                text: 'Cost-effective international freight shipping operating in the United States, Canada, and Mexico',
                to: 'frontend.services.international'
            }
        ]
    }),
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.calculator.date)
        },
    },
    watch: {
        'calculator.date' (val) {
            this.calculator.dateFormatted = this.formatDate(this.calculator.date)
        },
    },
    methods: {
        assign_and_redirect(){
            this.calculator_assign();
            this.calculator_redirect();
        },
        calculator_assign(){
            this.$store.dispatch('calculator/setForm', {
                form: this.form
            });
        },
        calculator_redirect(){
            this.$router.push({name: 'frontend.calculator'})
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    }
}
</script>

<style lang="scss">
.primary.header{
    position: relative;
    overflow: hidden;
    header{
        z-index: 1;
    }
    .v-btn.v-size--x-large{
        font-weight: 450;
        font-size: 14px;
        line-height: 22px;
        text-transform: none;
    }
    .airfreightservice-mainimage{
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
    }
}
</style>
